<template>
  <a-modal
    v-model="visible"
    title="上传打款凭证"
    width="550px"
    class="upload-payment"
    :okButtonProps="{
      props: {
        loading: this.btnLoading
      }
    }"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="clearfix">
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        :show-upload-list="false"
        :customRequest="upload"
        accept=".png, .jpg"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 435px;height: 200px" />
        <div
          v-else
          class="bg"
          style="width: 435px;height: 200px; background: #555555; line-height: 200px;color: #40a9ff;"
        >上传打款凭证（png/jpg）</div>
      </a-upload>
    </div>
  </a-modal>
</template>
  <script>
import { uploadImage } from '@/api/materialShop'
import { uploadFile } from '@/api/insurance'
export default {
  name: 'UploadPayment',
  data () {
    return {
      visible: false,
      imageUrl: '',
      orderNo: null,
      btnLoading: false
    }
  },
  methods: {
    $init (orderNo) {
      this.visible = true
      this.orderNo = orderNo
    },
    handleOk (e) {
        if (!this.imageUrl) {
        this.$message.error('请上传打款凭证')
      } else {
        const params = {
          filePath: this.imageUrl,
          orderNo: this.orderNo
        }
        this.btnLoading = true
        uploadFile(params).then(res => {
          if (res.success) {
            this.handleCancel()
            this.$message.success('请上传打款凭证成功')
            this.$eventBus.$emit('updateStatus')
          }
        }).finally(() => {
          this.btnLoading = false
        })
      }
    },
    handleCancel () {
      this.visible = false
      this.imageUrl = ''
    },
    upload (file) {
      const form = new FormData()
      form.append('file', file.file)
      this.$spin.show()
      uploadImage(form)
        .then(res => {
          this.imageUrl = res.path
        })
        .catch(() => {
            this.$message.error('打款凭证图片上传失败')
        })
        .finally(() => {
          this.$spin.hide()
        })
    }
  }
}
</script>

<style lang="less">
.upload-payment {
  .clearfix {
    width: 100%;
    padding: 0 25px;
    .bg {
      background: #555555;
      width: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: blue;
    }
  }
}
</style>
